import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CONFIG from '../../config/config.wods';

const WolfOfDumbStreet = () => {
    useEffect(() => {
        const glitchTexts = document.querySelectorAll('.glitch-text');

        const glitchText = (element) => {
            const original = element.textContent;
            const glitched = original
                .split('')
                .map((char) =>
                    Math.random() > 0.8
                        ? String.fromCharCode(char.charCodeAt(0) + 1)
                        : char
                )
                .join('');

            element.textContent = glitched;
            setTimeout(() => {
                element.textContent = original;
            }, 100);
        };

        const intervalId = setInterval(() => {
            const randomText =
                glitchTexts[Math.floor(Math.random() * glitchTexts.length)];
            glitchText(randomText);
        }, 2000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="layout-grid">
            {/* Header */}
            <header className="box header-box">
                <div>
                    <div className="glitch-text">DUMB MONEY MARKET</div>
                    <small>Predict the future</small>
                </div>
                <div className="ticker">$WODS</div>
            </header>

            {/* Left Column */}
            <SectionBox
                title={<span className="glitch-text">PREDICTION STATUS</span>}
                content={
                    <span className="glitch-text">
                        STATUS/W0DS: Too much hopium detected
                    </span>
                }
                urgentAlert="⚠️ CRITICAL COPIUM LEVELS"
            >
                <p>Loading next bad decision...</p>
                <div className="loading-bar"></div>
            </SectionBox>

            <SectionBox
                title={<span className="glitch-text">DUMB STREET CASINO</span>}
                content={
                    <span className="glitch-text">
                        Last Prediction: TOTALLY ACCURATE
                    </span>
                }
            >
                <p>Next Prediction: TRUST ME BRO</p>
                <button
                    className="app-button-community ticker"
                    onClick={() => window.open(CONFIG.SOCIAL_LINKS.TG, '')}
                >
                    Join the community
                </button>
            </SectionBox>

            <SectionBox
                title={<span className="glitch-text">Market Analysis</span>}
                content={
                    <span className="glitch-text">
                        Trust level: In $WODS we trust
                    </span>
                }
            >
                <div className="loading-bar"></div>
                <p className="ticker" style={{ marginTop: '10px' }}>
                    Will $BTC Hit $100,000 ?
                </p>
            </SectionBox>

            {/* Center Section */}
            <div className="center-section">
                <div className="logo-text">
                    <div>WOLF</div>
                    <div className="of">of</div>
                    <div>DUMB</div>
                    <div>STREET</div>
                </div>
                <button className="app-button" disabled>
                    Enter the App
                </button>
                <div className="coming-soon-text">Coming Soon™</div>
                <div className="social-links mt-4">
                    {Object.keys(CONFIG.SOCIAL_LINKS).map(
                        (social_name, index) => (
                            <Link
                                key={index}
                                to={''}
                                onClick={() =>
                                    window.open(
                                        CONFIG.SOCIAL_LINKS[social_name],
                                        '_blank'
                                    )
                                }
                                className="social-link"
                            >
                                {social_name}
                            </Link>
                        )
                    )}
                </div>
            </div>

            {/* Right Column */}
            <SectionBox
                title={<span className="glitch-text">DEGEN STATUS</span>}
                content={
                    <ul className="mt-2">
                        <li>Wallet: $100,000</li>
                        <li>Brain: SMOOTH</li>
                        <li>Trading: FOMO ONLY</li>
                    </ul>
                }
            >
                <p className="warning-text mt-2">
                    EXTREME DEGEN ACTIVITY DETECTED
                </p>
            </SectionBox>

            <SectionBox
                title={<span className="glitch-text">$WODS METRICS</span>}
                content={<span className="glitch-text">Price: MOON SOON™</span>}
                urgentAlert="🚨 BULLISH DIVERGENCE DETECTED"
            >
                <p>Supply: TRUST THE MATH</p>
                <div className="loading-bar"></div>
            </SectionBox>

            <SectionBox
                title={<span className="glitch-text">Prediction Feed</span>}
                content={
                    <span className="glitch-text">Loading alpha leaks...</span>
                }
            >
                <p>Source: Voices in head</p>
                <div className="loading-bar"></div>
            </SectionBox>

            {/* Footer */}
            <footer className="box footer-box">
                <div className="social-links">
                    copyright - Wolf of Dumb Street
                </div>
                <div>54 • 19 998.5 • $WODS TO THE MOON</div>
            </footer>
        </div>
    );
};

const SectionBox = ({ title, content, children, urgentAlert }) => (
    <div className="box">
        <h3>{title}</h3>
        <p>{content}</p>
        {children}
        {urgentAlert && <p className="urgent-alert">{urgentAlert}</p>}
    </div>
);

export default WolfOfDumbStreet;
